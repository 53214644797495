<template>
  <div class="common-padding">
    <el-drawer class="appealRecordDetail-drawer" title="品牌方质检详情" :visible.sync="drawer" :append-to-body="true" direction="rtl" :before-close="handleClose">
      <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="drawer-container">
        <div class="drawer-left">
          <div>
              <div class="detail-title">
                  <span>{{ tableRow.qualityCode || "--" }}</span>
              </div>
              <div class="detail-content">
                  <div class="detail-item">店铺名称：<span>{{tableRow.shopName || '--'}}</span></div>
                  <div class="detail-item">所属平台：<span>{{tableRow.platformName || '--'}}</span></div>
                  <div class="detail-item">所属类别：<span>{{tableRow.categoryName || '--'}}</span></div>
                  <div class="detail-item">顾客账号：<span>{{tableRow.customerAccount || '--'}}</span></div>
                  <div class="detail-item">客服子账号：<span>{{tableRow.subaccountName || '--'}}</span></div>
                  <div class="detail-item">反馈时间：<span>{{tableRow.qualityTime || '--'}}</span></div>
              </div>
          </div>
          <el-tabs class="leftTabs" v-model="activeNameLeft">
            <el-tab-pane label="聊天记录" name="ChatRecord">
              <div>
                <div class="chatItem" v-for="(item, index) in chatData" :key="index">
                  <div class="chatItem-title">
                    <div>{{item.fromId}}</div>
                    <div>{{DateTimeTransform(item.msgTime)}}</div>
                  </div>
                  <div class="chatItem-content" v-if="item.msgType === 0">{{item.msgContent}}</div>
                  <a :href="item.msgContent" target="_blank" v-else-if="item.msgType === 1">点击跳转</a>
                  <span class="chatItem-content" v-else-if="item.msgType === 2" >【{{ item.msgContent }}】--苹果手机表情+文字（不兼容）</span>
                  <span class="chatItem-content" v-else-if="item.msgType === 3" >【表情图暂不支持查看】</span>
                  <a :href="item.msgContent" target="_blank" v-else-if="item.msgType === 5" >淘宝链接--点击查看
                  </a>
                  <a target="_blank" :href="item.msgContent" v-else-if="item.msgType === 6" >【视频资源——点击查看】</a>
                  <img :src="item.msgContent" v-if="item.msgType === 7" @click="showImg(item.msgContent)" />
                  <span class="chatItem-content" v-else-if="item.msgType === 9">【{{ item.msgContent }}】</span>
                  <span class="chatItem-content" v-else-if="item.msgType === 10">[{{ item.msgContent }}] </span>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="品牌方质检" name="problem">
              <div class="detail-content" style="margin-top: 16px;width:100%">
                <div>
                  <div class="detail-item">问题大类：<span>{{ tableRow.classificationQuality && tableRow.classificationQuality.bigList[0] || '--'}}</span></div>
                  <div class="detail-item" style="margin-top: 16px;">问题点：<span>{{ tableRow.classificationQuality && tableRow.classificationQuality.pointList[0] || '--'}}</span></div>
                </div>
                <div class="detail-remark"><span>问题描述：</span> <span>{{ tableRow.classificationQuality && tableRow.classificationQuality.descList[0] || '--'}}</span></div>
                <div class="detail-remark"><span>备注：</span> <span>{{ tableRow && tableRow.remark || '--'}}</span></div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <!-- <div class="drawer-right">
          <el-tabs v-model="activeName">
            <el-tab-pane label="工作记录" name="appeal">
                <common-work-log :followRecordList="followRecordList"></common-work-log>
            </el-tab-pane>
          </el-tabs>
        </div> -->
      </div>
    </el-drawer>
    <el-dialog title="申诉详情" :append-to-body="true" :visible.sync="appealVisible" width="520px" :close-on-click-modal="false">
      <div>
        <el-form :model="appealEditForm" label-width="100px" class="demo-ruleForm">
          <el-form-item class="formItem" label="发起人：">{{appealEditForm.createName || '--'}}</el-form-item>
          <el-form-item class="formItem" label="发起时间：">{{appealEditForm.createTime || '--'}}</el-form-item>
          <el-form-item class="formItem" label="申诉原因：">{{appealEditForm.appealContent || '--'}}</el-form-item>
          <el-form-item class="formItem" label="附件：">
            <div>
              <div class="fileItem" v-for="(item, index) in editFilesList" :key="index">
                <div><common-picture :fileUrl="item.fileUrl" :fileType="Config.fileType.imgType.includes(item.fileUrl.split('.')[item.fileUrl.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item.fileName"></common-picture></div>
                <div class="common-blue-btn" style="font-size: 14px" @click="() => {downLoad(item.fileUrl)}">下载</div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="appealVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" :loading="btnLoading" type="primary" @click="() => {appealEdit()}">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="拒绝申诉" :append-to-body="true" :visible.sync="voidVisible" width="480px" :close-on-click-modal="false">
      <div>
        <el-form :model="voidForm" :rules="voidRules" ref="voidForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="拒绝原因：" prop="appealRemark">
            <el-input class="common-screen-input_100" type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请输入内容" v-model="voidForm.appealRemark"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="voidVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" :loading="btnLoading" type="primary" @click="() => {examineAppeal('voidForm')}">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="同意申诉" width="580px" :visible.sync="agreeVisible" :append-to-body="true" :close-on-click-modal="false">
      <el-form ref="formRef" :model="agreeForm" label-width="120px">
        <el-form-item label="问题分类">
          <el-cascader
            class="common-screen-input_85"
            ref="myCascader"
            v-model="agreeForm.classificationQualityIds"
            :options="qulityoptions"
            :props="cateProps"
            clearable
            :show-all-levels="false"
            @change="(val) => changeQuality(val)"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="品牌质检分类">
          <el-cascader
            class="common-screen-input_85"
            v-model="agreeForm.classificationBrandIds"
            :options="brandoptions"
            :props="cateProps"
            clearable
            :show-all-levels="false"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            class="common-screen-input_85"
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 6 }"
            v-model="agreeForm.remark"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="业务类型">
          <el-select class="common-screen-input_85" v-model="agreeForm.businessType" placeholder="请选择" clearable filterable >
            <el-option label="售前" value="售前"></el-option>
            <el-option label="售中" value="售中"></el-option>
            <el-option label="售后" value="售后"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="减分详情">
          <el-input class="common-screen-input_85" :autosize="{ minRows: 6, maxRows: 6 }" type="textarea" :value="agreeForm.pointsDeduction" ></el-input>
        </el-form-item>
        <el-form-item label="扣款金额">
          <el-input class="common-screen-input_85" :value="agreeForm.amount" ></el-input>
        </el-form-item>
        <el-form-item label="评审评分">
          <el-input class="common-screen-input_85" :value="agreeForm.score" ></el-input>
          <el-button type="text" class="dialogBtn" @click="reset()">恢复
          </el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="() => { agreeVisible = false }">取 消</el-button>
        <el-button :loading="btnLoading" type="primary" @click="() => agreeCommit()" >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex"
import { Config, tableColumn, DateTimeTransform } from "../../../utils/index.js"
import CommonPicture from "../../../components/common/commonPicture.vue"
import { upload } from "../../../service/upload.js"
import { qualityHisDetail, appealList, examineAppeal, qualityList, qualityRecordList } from "../../../service/service.js"
// import CommonWorkLog from "../../../components/common/commonWorkLog.vue"
import { workLog } from "../../../service/common.js"
export default {
  props: ["drawer", "handleClose"],
  components: { CommonPicture },
  data() {
    return {
      DateTimeTransform,
      loading: false,
      voidVisible: false,
      appealVisible: false,
      agreeVisible: false,
      Config,
      tableColumn,
      activeName: "appeal", // tabs
      activeNameLeft: "ChatRecord", // 左侧tabs
      recordContent: '', // 申诉原因
      appealTableData: [], // 申诉列表
      appealParams: { // 申诉列表
        pageNum: 1,
        pageSize: 10
      }, 
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      voidForm: {},  // 作废表单
      voidRules: { // 校验规则-作废
        appealRemark: [{ required: true, message: "请填写拒绝原因", trigger: "change" }]
      },
      agreeForm: {
        classificationQualityIds: [],
        classificationBrandIds: [],
        pointsDeduction: '',
        score: 100,
        remark: '',
        businessType: '',
        amount: 0
      },
      appealEditForm: {},
      filesList: [],  // 申诉文件上传
      editFilesList: [], // 申诉文件编辑
      tableRow: {}, // 列表一行信息
      chatData: [], // 聊天数据
      followRecordList: [], // 工作记录
      qulityoptions: [], //问题 级联的数据源
      brandoptions: [], //品牌 级联的数据源
      cateProps: {
        value: "id",
        label: "name",
        children: "childrenList",
        multiple: true
      },
    };
  },
  created() {
    this.sendChild()
    this.getBrandIds()
  },
  computed: {
    ...mapState(["btnLoading"])
  },
  methods: {
    async qualityRecordList () { // 刷新详情
      let { data } = await qualityRecordList({id: this.tableRow.id})
      this.tableRow = data.list[0] || {}
      console
    },
    async qualityRecordLists (id) { // 刷新详情
      let { data } = await qualityRecordList({id: id,brandCustomerId: this.$sto.get(Config.constants.customerInfo).id})
      this.tableRow = data.list[0] || {}
      this.workLog(id)
      this.qualityHisDetail()
    },
    async agreeCommit () { // 同意申诉提交
      let agreeForm = {...this.agreeForm}
      if (agreeForm.classificationQualityIds) {
        let classificationQualityIds = [];
        agreeForm.classificationQualityIds.map((item) => {
          if (item) {
            classificationQualityIds.push(item[item.length - 1]);
          }
        });
        agreeForm.classificationQualityIds = classificationQualityIds;
      }
      if (agreeForm.classificationBrandIds) {
        let classificationBrandIds = [];
        agreeForm.classificationBrandIds.map((item) => {
          if (item) {
            classificationBrandIds.push(item[item.length - 1]);
          }
        });
        agreeForm.classificationBrandIds = classificationBrandIds;
      }
      this.$vuex.commit("btnLoading", true);
      let res = await examineAppeal(agreeForm)
      this.$vuex.commit("btnLoading", false);
      if (res.code == 200) {
        this.$message.success('审核成功')
        this.appealList()
        this.workLog(this.tableRow.id)
        this.qualityRecordList()
      }
      this.agreeVisible = false
    },
    reset () { // 评分重置
      this.agreeForm = {
        classificationQualityIds: [],
        classificationBrandIds: [],
        pointsDeduction: '',
        score: 100,
        amount: 0,
      }
    },
    changeQuality (val) { // 问题分类值改变
    let qulityoptions = [...this.qulityoptions]
    let newVal = [...val]
    let newArr = []
    let newPointDeduction = 0
    let newAmountDeduction = 0
    qulityoptions.map((item) => {
      if (item.childrenList && item.childrenList.length) {
        item.childrenList.map((itemTwo) => {
          if (itemTwo.childrenList && itemTwo.childrenList.length) {
            itemTwo.childrenList.map((itemThRee) => {
              console.log(itemThRee)
              newVal.map((itemVal)=>{
                if (itemThRee.id == itemVal[itemVal.length - 1]) {
                  newArr.push(`【${itemThRee.name}】 -${itemThRee.pointDeduction}`)
                  if (itemThRee.pointDeduction) {
                    newPointDeduction += Number(itemThRee.pointDeduction)
                    newAmountDeduction += Number(itemThRee.amountDeduction)
                  }
                }
              })
            })
          }
        })
      }
    })
    this.agreeForm.pointsDeduction = newArr.join(',')
    this.agreeForm.score = (100 - newPointDeduction)
    this.agreeForm.amount =  newAmountDeduction
    },
    async sendChild() { // 问题分类
      const { data } = await qualityList({type: 'quality'});
      this.qulityoptions = data.list;
    }, 
    async getBrandIds() {  // 品牌问题分类
      const { data } = await qualityList({type: 'brand'});
      this.brandoptions = data.list;
    },
    async workLog(id) { // 工作日志
      let resData = (await workLog({id: id, type: 3})).data
      if (resData && resData.length) {
        resData.map((item) => {
          if (item.value) {
            item.value = JSON.parse(item.value)
          } else {
            item.value = []
          }
        })
      }
      this.followRecordList = [...resData]
    },
    downLoad (url) { // 下载文件
      window,location.href = url
    },
    async examineAppeal(formName) { // 拒绝申诉
      let params = {...this.voidForm}
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.$vuex.commit("btnLoading", true);
          let res = await examineAppeal(params)
          this.$vuex.commit("btnLoading", false);
          if(res.code == 200) {
            this.$message.success("申诉拒绝")
            this.appealList()
            this.workLog(this.tableRow.id)
            this.qualityRecordList()
          }
          this.voidVisible = false
        } else {
          return false;
        }
      });
    },
    async appealList() { // 申诉列表
    let params = {...this.appealParams}
    params.qualityCode = this.tableRow.qualityCode
      let { data } = await appealList(params)
      this.appealTableData = data.list || []
      this.pagination.total = data.total;
    },
    async qualityHisDetail() {// 聊天记录
      let params = { qualityCode: this.tableRow.qualityCode }
      let { data } = await qualityHisDetail(params)
      this.chatData = data
    },
    tableParams (row) { // 获取列表的值
      this.tableRow = row
      this.qualityHisDetail()
      this.appealList()
      this.workLog(row.id)
    },
    appealEditShow (row) { // 申诉编辑弹窗
      let newArr = []
      if (row.fileNames) {
        let fileNamesArr = row.fileNames.split(',')
        let fileUrlsArr = row.fileUrl.split(',')
        fileNamesArr.map((item, index) => {
          let fileType = ''
          let format = item.split('.')[item.split('.').length - 1]
          if ( format == "bmp" || format == "jpg" || format == "png" || format == "gif" || format == "jpeg" ) {
            fileType = "img";
          }
          newArr.push({
            fileType: fileType,
            fileName: item,
            fileUrl: fileUrlsArr[index],
          })
        })
        this.editFilesList = newArr
      }
      this.appealEditForm = {
        id: row.id,
        qualityCode: this.tableRow.qualityCode,
        appealContent: row.appealContent,
        createName: row.createName,
        createTime: row.createTime,
      }
      this.appealVisible = true
    },
    agreeShow (id) { // 同意按钮
      this.agreeForm = {
        appealId: id,
        appealStatus: 'adopt',
        classificationQualityIds: [],
        classificationBrandIds: [],
        pointsDeduction: '',
        score: 100,
        remark: '',
        businessType: '',
        amount: 0
      }
      this.agreeVisible = true
    },
    voidShow (id) { // 拒绝按钮
      this.voidForm = {
        appealId: id,
        appealStatus: 'fail',
        appealRemark: ''
      }
      this.voidVisible = true
      if (this.$refs["voidForm"]) {
        this.$refs["voidForm"].resetFields();
      }
    },
    editFile (id) { // 删除申诉文件
      let editFilesList = [...this.editFilesList]
      this.editFilesList = editFilesList.filter((item)=>{
        return item.fileId != id
      })
    },
    deleteFile (id) { // 删除申诉文件
      let filesList = [...this.filesList]
      this.filesList = filesList.filter((item)=>{
        return item.fileId != id
      })
    },
    async chooseFile(e) { // 发起申诉选择文件
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
        e.target.value = "";
        return this.$message.error('文件大小不能超过50M')
      }
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
      }
      this.loading = true
      let resData = (await upload({ file: file })).data;
      this.loading = false
      let fileItem = {
        fileType: "",
        fileName: file.name,
        fileId: resData.id,
        fileUrl: resData.filename,
      };
      if ( format == "bmp" || format == "jpg" || format == "png" || format == "gif" || format == "jpeg" ) {
        fileItem.fileType = "img";
      }
      this.filesList.push(fileItem);
    },
    pageFun(e, type) { // 分页
      this.appealParams[type] = e;
      this.appealList()
    },
  },
};
</script>
<style lang="less" scoped>
.formItem {
  margin-bottom: 0;
}
.fileItem {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.appealRecordDetail-drawer {
  text-align: left;
  color: #101010;
  .drawer-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .drawer-left {
      padding: 0 24px;
      text-align: left;
      // width: 1150px;
      width: 100%;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
      .detail-title {
        line-height: 78px;
        span:nth-of-type(1) {
          font-size: 18px;
          margin-right: 16px;
        }
      }
      .detail-content {
        width: 900px;
      }
      .detail-item {
        width: 32%;
        display: inline-block;
        margin-bottom: 16px;
        color: #999;
        span {
          color: #666;
        }
      }
      .detail-remark {
        display: flex;
        width: 80%;
        span {
          display: inline-block;
          &:nth-of-type(1) {
            color: #999;
            white-space: nowrap;
          }
          &:nth-of-type(2) {
            color: #666;
          }
        }
      }
      .leftTabs {
        margin-top: 36px;
        .chatItem {
          color: #101010;
          font-size: 14px;
          margin-top: 8px;
          .chatItem-title {
            display: flex;
            justify-content: space-between;
            line-height: 38px;
          }
          .chatItem-content {
            line-height: 38px;
            background: #F7F8FB;
            padding-left: 10px;
          }
        }
      }
    }
    .drawer-right {
      width: 500px;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      .appeal-commit {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        align-items: center;
        .addFileBtn {
          height: 20px;
          color: #1890ff;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: relative;
          .addFileInput {
            position: absolute;
            left: 0;
            top: 0;
            width: 120px;
            height: 20px;
            opacity: 0;
            cursor: pointer;
            font-size: 0;
          }
        }
      }
      
    }
  }
  /deep/ .el-drawer__open .el-drawer.rtl {
      width: 60% !important;
  }
  /deep/ .el-drawer__body {
      padding: 0;
  }
  /deep/ .el-tabs__content {
      padding: 0 8px 0 16px;
  }
}

</style>
